<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <v-card>
          <!-- PESQUISA -->
          <v-toolbar flat class="transparent pt-2">
            <v-spacer></v-spacer>
            <h1>Total da venda: R$ {{total | currency}}</h1>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text v-if="fatura_parcelas.length === 0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs4 md4>
                    <v-select
                      :rules="campoRules"
                      required
                      v-model="parametros.forma_pagamento"
                      :items="forma_pagamentos"
                      placeholder=" "
                      outline
                      label="Forma de pagamento"
                      item-value="codigo"
                      item-text="descricao"
                      @change="changeForma($event)"
                    ></v-select>
                  </v-flex>

                  <v-flex xs4 md4>
                    <v-select
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      required
                      v-model="parametros.parcelas"
                      :items="parcelas"
                      placeholder=" "
                      outline
                      label="Quantidade de parcelas"
                      item-value="codigo"
                      item-text="descricao"
                    ></v-select>
                  </v-flex>

                  <v-flex xs4 md4>
                    <!-- <v-btn color="green" class="white--text">Gerar</v-btn> -->
                  </v-flex>
                  <v-flex xs3 md3>
                    <v-text-field
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      placeholder=" "
                      outline
                      label="Número do cartão"
                      id="num_cartao"
                      name="num_cartao"
                      mask="#### #### #### ####"
                      v-model="parametros.cartao_numero"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs5 md5>
                    <v-text-field
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      placeholder=" "
                      outline
                      label="Nome do cartão"
                      id="cartao_nome"
                      name="cartao_nome"
                      v-model="parametros.cartao_nome"
                      @input="$event => parametros.cartao_nome = $event.toUpperCase()"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 md2>
                    <v-text-field
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      placeholder=" "
                      outline
                      label="Validade"
                      id="cartao_validade"
                      name="cartao_validade"
                      v-model="parametros.cartao_validade"
                      mask="##/##"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 md2>
                    <v-text-field
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      placeholder=" "
                      outline
                      label="CVV"
                      id="cartao_cvv"
                      name="cartao_cvv"
                      v-model="parametros.cartao_cvv"
                      mask="####"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 md4>
                    <v-text-field
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      placeholder=" "
                      outline
                      label="UF"
                      id="cobranca_uf"
                      name="cobranca_uf"
                      v-model="parametros.cobranca_uf"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs4 md4>
                    <v-text-field
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      placeholder=" "
                      outline
                      label="Cidade"
                      id="cobranca_cidade"
                      name="cobranca_cidade"
                      v-model="parametros.cobranca_cidade"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs4 md4>
                    <v-text-field
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      placeholder=" "
                      outline
                      label="Bairro"
                      id="cobranca_bairro"
                      name="cobranca_bairro"
                      v-model="parametros.cobranca_bairro"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md6>
                    <v-text-field
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      placeholder=" "
                      outline
                      label="Logradouro"
                      id="cobranca_rua"
                      name="cobranca_rua"
                      v-model="parametros.cobranca_rua"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs3 md3>
                    <v-text-field
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      placeholder=" "
                      outline
                      label="Número"
                      id="cobranca_numero"
                      name="cobranca_numero"
                      v-model="parametros.cobranca_numero"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs3 md3>
                    <v-text-field
                      v-if="parametros.forma_pagamento === 'C'"
                      :rules="campoRules"
                      placeholder=" "
                      outline
                      label="Cep"
                      id="cobranca_cep"
                      name="cobranca_cep"
                      v-model="parametros.cobranca_cep"
                      mask="##.###-###"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-data-table
            :headers="headers"
            :items="fatura_parcelas"
            class="elevation-1"
            item-key="id"
            :rows-per-page-items="[12]"
            v-if="fatura_parcelas.length > 0"
          >
            <template slot="no-data">
              <div class="text-xs-center">Sem registros.</div>
            </template>
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.parcela_numero }}</td>
                <td>{{ props.item.vencimento }}</td>
                <td>{{ props.item.valor | currency}}</td>
                <td>{{ props.item.data_quitacao ? props.item.data_quitacao : 'Em aberto'}}</td>
                <td class="text-xs-center" >
                  <v-tooltip bottom v-if="props.item.pag_sigla === 'B' && !props.item.data_quitacao">
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        class="mr-2 td-cursor"
                        slot="activator"
                        @click="printBoleto(props.item)"
                        color="red"
                        v-on="on"
                      >print</v-icon>
                    </template>
                    <span>Imprimir boleto</span>
                  </v-tooltip>
                  <label v-else> - </label>
                </td>
              </tr>
            </template>
            <template v-slot:no-results>
              <div class="text-xs-center">Sem resultados para a pesquisa "{{ search }}".</div>
            </template>
            <template v-slot:footer>
              <td :colspan="headers.length">
                <div class="text-xs-right">
                  <b>Forma de pagamento {{fatura_parcelas[0].forma_pagamento}}, gerado em {{fatura_parcelas[0].data_cadastro}}.</b>
                </div>
              </td>
            </template>
          </v-data-table>

          <v-toolbar flat class="transparent" v-if="!finalizado">
            <v-btn color="primary" class="white--text" @click="voltar()">Voltar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              class="white--text"
              :disabled="!valid || fatura_parcelas.length > 0"
              @click="finalizar(parametros)"
            >Finalizar</v-btn>
          </v-toolbar>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
const faturamentoModule = () => import('./../../services/faturamento')
const clienteModule = () => import('./../../services/cliente')

import * as util from './../../services/util'
import * as moment from 'moment'

export default {
  name: 'ContratoFaturamento',
  props: ['finalizado'],
  data () {
    return {
      total: 0,
      valid: true,
      campoRules: [
        v => !!v || 'Campo é obrigatório.',
      ],
      headers: [
        { text: 'Parcela', align: 'left', value: 'parcela_numero' },
        { text: 'Vencimento', align: 'left', value: 'vencimento' },
        { text: 'Valor(R$)', align: 'left', value: 'valor' },
        { text: 'Data do pagamento', align: 'left', value: 'data_quitacao' },
        { text: 'Opções', align: 'center', value: '' },
      ],
      fatura_parcelas: [],
      parametros: {
        parcelas: 1,
        forma_pagamento: 'B',
        cartao_numero: null,
        cartao_cvv: null,
        cartao_validade: null,
        cartao_nome: null,
        contrato_codigo: null,
        cobranca_uf: null,
        cobranca_cidade: null,
        cobranca_bairro: null,
        cobranca_rua: null,
        cobranca_numero: null,
        cobranca_cep: null
      },
      forma_pagamentos: [
        {
          codigo: 'C',
          descricao: 'Cartão de Crédito'
        },
        {
          codigo: 'B',
          descricao: 'Boleto'
        },
      ],
      parcelas: [
        {
          codigo: 1,
          descricao: '1x'
        },
        {
          codigo: 2,
          descricao: '2x'
        },
        {
          codigo: 3,
          descricao: '3x'
        }
      ]
    }
  },
  created () {
    this.$eventHub.$on('contratoFaturamento-getTotal', this.getTotal)
  },
  beforeDestroy () {
    this.$eventHub.$off('contratoFaturamento-getTotal')

  },
  mounted () {
    this.getTotal()
    this.parametros.contrato_codigo = this.$route.params.venda
    this.getParcelas()
    // this.getDadosCobranca()
  },

  methods: {
    async finalizar (parametros) {
      if (!this.$refs.form.validate()) {
        return
      }
      // VALIDA CARTÃO DE CREDITO
      if (parametros.forma_pagamento === 'C') {
        let cartaoValid = this.cartaoIsValid(parametros.cartao_numero, parametros.cartao_cvv, parametros.cartao_validade)
        if (!cartaoValid) {
          return
        }
      }
      let faturamentoService = await faturamentoModule()
      parametros.contrato_codigo = this.$route.params.venda
      let resp = await faturamentoService.addFaturamento(this.$axios, parametros)
      if (resp.status === 200 && resp.data.success) {
        this.$eventHub.$emit('contratoDetalhes-getParcelas', () => true)
        this.$toast.success('Sucesso ao gerar faturamento')
        await this.getParcelas()
        this.$eventHub.$emit('contratoSegurados-getAll', () => true)
      } else {
        if (parametros.forma_pagamento === 'C') {
          this.$swal('Transação recusada, não autorizada.', '', 'error')
        } else {
          this.$swal('Não foi possível gerar o boleto.', '', 'error')
        }
      }

    },
    voltar () {
      this.$eventHub.$emit('contratoDetalhes-goStep', 2)
    },
    async getTotal () {
      if (this.$route.params.venda) {
        let faturamentoService = await faturamentoModule()
        let resp = await faturamentoService.getTotal(this.$axios, this.$route.params.venda)
        if (resp.status === 200 && resp.data) {
          this.total = resp.data.total || 0
        }
      }
    },
    async getParcelas () {
      if (this.$route.params.venda) {
        let faturamentoService = await faturamentoModule()
        let resp = await faturamentoService.getParcelasByContrato(this.$axios, this.$route.params.venda)
        if (resp.status === 200 && resp.data) {
          this.fatura_parcelas = resp.data
        } else {
          this.fatura_parcelas = []
        }
      }
    },
    async changeForma (event) {
      this.parametros.parcelas = 1
      if (event !== 'C') {
        this.parametros.cartao_numero = null
        this.parametros.cartao_cvv = null
        this.parametros.cartao_validade = null
        this.parametros.cartao_nome = null
        this.parametros.cobranca_uf = null
        this.parametros.cobranca_cidade = null
        this.parametros.cobranca_bairro = null
        this.parametros.cobranca_rua = null
        this.parametros.cobranca_numero = null
        this.parametros.cobranca_cep = null
      } else {
        this.getDadosCobranca()
      }
    },
    async getDadosCobranca () {
      let clienteService = await clienteModule()
      let resp = await clienteService.getClienteByContrato(this.$axios, this.$route.params.venda)
      if (resp.status === 200 && resp.data) {
        let cliente = resp.data
        this.parametros.cobranca_uf = cliente.estado_sigla
        this.parametros.cobranca_cidade = cliente.cidade_nome
        this.parametros.cobranca_bairro = cliente.bairro
        this.parametros.cobranca_rua = cliente.logradouro
        this.parametros.cobranca_numero = cliente.numero
        this.parametros.cobranca_cep = cliente.cep
      }
    },
    cartaoIsValid (cartao_numero, cartao_cvv, cartao_validade) {
      let cardValid = util.validateCard(cartao_numero)
      if (!cardValid) {
        this.$swal('Número de cartão inválido', '', 'error')
        return false
      }
      let cvvValid = util.validateCVV(cartao_numero, cartao_cvv)
      if (!cvvValid) {
        this.$swal('Número do CVV inválido', '', 'error')
        return false
      }
      let expValid = moment().format('YYYY-MM') <= moment(cartao_validade, 'MM/YY').format('YYYY-MM')
      if (!expValid) {
        this.$swal('Validade do cartão expirada', '', 'error')
        return false
      }
      return true
    },
    printBoleto (payload) {
      if (payload.url_boleto) {
        let win = window.open(payload.url_boleto, '_blank')
        win.focus()
      }
    }
  }
}
</script>